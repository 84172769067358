import React, { createContext, useState, useContext } from "react";

const DashboardContext = createContext({});

let default1 = "#002855";
let default2 = "#044FBF";
let default3 = "#80D6FF";
let default4 = "#9AB1CB";
let defaultGradientText =
  "-webkit-linear-gradient(45deg, #002855 0%, #002855 0%, #002855 0%, #044FBF 100%)";

let brave1 = "#9D0208";
let brave2 = "#DC2F02";
let brave3 = "#F48C06";
let brave4 = "#D9D9D9";
let braveGradientText =
  "-webkit-linear-gradient(45deg, #9D0208 0%, #9D0208 0%, #9D0208 0%, #DC2F02 100%)";

let ocean1 = "#1985A1";
let ocean2 = "#07BEB8";
let ocean3 = "#C9E4CA";
let ocean4 = "#D9D9D9";
let oceanGradientText =
  "-webkit-linear-gradient(45deg, #1985A1 0%, #1985A1 0%, #1985A1 0%, #07BEB8 100%)";

let nature1 = "#007F5F";
let nature2 = "#2B9348";
let nature3 = "#C9E4CA";
let nature4 = "#D9D9D9";
let natureGradientText =
  "-webkit-linear-gradient(45deg, #007F5F 0%, #007F5F 0%, #007F5F 0%, #2B9348 100%)";

function DashboardProvider(props) {
  // <-- static data
  const [totalApiStatic, setTotalApiStatic] = useState(null);
  const [totalUserStatic, setTotalUserStatic] = useState(null);
  const [totalUserPerTenantStatic, setTotalUserPerTenantStatic] =
    useState(null);
  const [tenant, setTenant] = useState(null);

  // <-- loading
  const [loadingTenant, setLoadingTenant] = useState(false);
  const [loadingTotalUserPerTenant, setLoadingTotalUserPerTenant] =
    useState(false);
  const [loadingTotalUser, setLoadingTotalUser] = useState(false);
  const [loadingTotalApi, setLoadingTotalApi] = useState(false);

  const globalColor = () => {
    if (tenant?.["color_name"] === brave1) {
      return {
        color1: brave1,
        color2: brave2,
        color3: brave3,
        color4: brave4,
        gradient: braveGradientText,
      };
    } else if (tenant?.["color_name"] === ocean1) {
      return {
        color1: ocean1,
        color2: ocean2,
        color3: ocean3,
        color4: ocean4,
        gradient: oceanGradientText,
      };
    } else if (tenant?.["color_name"] === nature1) {
      return {
        color1: nature1,
        color2: nature2,
        color3: nature3,
        color4: nature4,
        gradient: natureGradientText,
      };
    }

    return {
      color1: default1,
      color2: default2,
      color3: default3,
      color4: default4,
      gradient: defaultGradientText,
    };
  };

  return (
    <DashboardContext.Provider
      value={{
        totalApiStatic,
        setTotalApiStatic,
        totalUserStatic,
        setTotalUserStatic,
        totalUserPerTenantStatic,
        setTotalUserPerTenantStatic,
        tenant,
        setTenant,
        loadingTenant,
        setLoadingTenant,
        loadingTotalUserPerTenant,
        setLoadingTotalUserPerTenant,
        loadingTotalUser,
        setLoadingTotalUser,
        loadingTotalApi,
        setLoadingTotalApi,
        globalColor: globalColor(),
      }}
      {...props}
    />
  );
}

export const useDashboardContext = () => useContext(DashboardContext);

export { DashboardContext, DashboardProvider };
